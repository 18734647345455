import fullscreenPlugin from "./fullscreenPlugin";
import inlinePlugin from "./inlinePlugin";
import loadEnvironmentConfigUrl from "./loadEnvironmentConfigUrl";
import { setAndGetUID, getDomain } from "./chamaileonTokens.js";

let isChamaileonSdkInited = false;
export default async function (sdkConfig) {
	if (isChamaileonSdkInited) throw new Error("Chamaileon SDK is already initialized");
	if (!sdkConfig) throw new Error("Chamaileon SDK config is required");

	// append the container to the parentElem
	const { container, parentElem = document.body } = sdkConfig;
	let chamaileonPluginsWrapper;
	if (container && typeof container === "string") {
		chamaileonPluginsWrapper = document.querySelector(sdkConfig.container);
		if (!chamaileonPluginsWrapper) {
			throw new Error(`${sdkConfig.container} is not a valid query selector`);
		}
	}
	if (container && container instanceof HTMLElement) {
		if (sdkConfig.container.innerHTML.trim()) throw new Error("'container' must reference an empty DOM node.");
		chamaileonPluginsWrapper = sdkConfig.container;
	}
	if (!container) {
		chamaileonPluginsWrapper = document.createElement("div");
		chamaileonPluginsWrapper.id = "plugins-wrapper";
		chamaileonPluginsWrapper.style.position = "relative";
		chamaileonPluginsWrapper.style.zIndex = "2001";
	}
	let _parentElem = parentElem;
	if (typeof parentElem === "string") {
		_parentElem = document.querySelector(parentElem);
		if (!_parentElem) throw new Error(`${parentElem} is not a valid query selector`);
	}
	if (!(parentElem instanceof HTMLElement)) {
		throw new Error("'parentElem' must reference an empty DOM node.");
	}
	_parentElem.appendChild(chamaileonPluginsWrapper);
	delete sdkConfig.parentElem;
	delete sdkConfig.container;

	if (!sdkConfig.environmentName) {
		sdkConfig.environmentName = "sdk-prod";
	}

	if (!sdkConfig.accessToken) {
		throw new Error("Chamaileon SDK needs an accessToken");
	}

	const newAccessToken = {
		timeStamp: sdkConfig.accessToken.timeStamp || Date.now(),
		value: sdkConfig.accessToken.value || sdkConfig.accessToken,
	};

	sdkConfig.accessToken = newAccessToken;

	sdkConfig.environmentConfigUrl = ((sdkConfig.environmentName.includes("dev")) ? "http://localhost:50004/configs/%ENVNAME%.json" : "https://config.chamaileon.io/%ENVNAME%.json");
	await loadEnvironmentConfigUrl({ environmentConfigUrl: sdkConfig.environmentConfigUrl, environmentName: sdkConfig.environmentName });

	sdkConfig.uid = setAndGetUID(sdkConfig.environmentName);
	sdkConfig.domain = getDomain(sdkConfig.environmentName);

	isChamaileonSdkInited = true;

	function destroy() {
		if (!isChamaileonSdkInited) return;
		isChamaileonSdkInited = false;
		chamaileonPluginsWrapper.remove();
		delete window.chamaileonEnvironmentConfig;
		delete window.createLogo;
	}

	return {
		createInlinePlugin: inlinePlugin({ sdkConfig }),
		createFullscreenPlugin: fullscreenPlugin({ sdkConfig, chamaileonPluginsWrapper }),
		destroy,
	};
}
